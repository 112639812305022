import React from 'react';
import ServiceTemplate from 'templates/ServiceTemplate';

import GeometricHedge from 'images/geometric-hedges.jpg';

const bullets = [
  'Surface what matters most to buyers',
  'Craft the hard-hitting message',
  'Put your competitors in a box',
];

const GoToMarketStrategyPage = () => (
  <ServiceTemplate
    bullets={bullets}
    excludeUrl="/go-to-market-strategy"
    imagePath={GeometricHedge}
    metaDescription="OutPosition Go-to-Market Strategic Services - Surface what matters most to buyers and craft the hard-hitting message"
    outText="Position"
    title="Go-to-market strategic services"
  >
    <div>
      <h4 className="tc ttu">
        Take command of your market
      </h4>

      <p className="intro--paragraph">
        Companies love to talk about their technology and services. Buyers
        don’t care. To take command of your market, you need an <a href="#buyer-voice">“outside-in” approach</a> to
        your go-to-market strategy, one that’s based on buyer motivation
        and differentiated value.
      </p>

      <h4 className="tc ttu">
        Go-to-market <strong>quick start</strong>
      </h4>

      <p className="intro--paragraph">
        The Go-to-market Quick Start is a packaged service that delivers a
        strategy and associated deliverables in an accelerated timeframe.
        Deliverables can include all or some of the below, depending on
        what makes the most sense for your company.
      </p>

      <div className="deliverables pv4">
        <div className="container">
          <h3 className="white">
            Deliverables
          </h3>

          <div className="db flex-l">
            <div className="bucket w-100 w-25-l pb3 pb0-ns pr0 pr3-l">
              <h5 className="ttu">
                Identity
              </h5>

              <p>
                We deliver a clear and focused identity for your company and its product
                and services.
              </p>
            </div>

            <div className="bucket w-100 w-25-l pb3 pb0-ns ph0 ph3-l">
              <h5 className="ttu">
                Targets
              </h5>

              <p>
                We identify or confirm priority target buyers and market/industry segments.
              </p>
            </div>

            <div className="bucket w-100 w-25-l pb3 pb0-ns ph0 ph3-l">
              <h5 className="ttu">
                Messaging and Positioning
              </h5>

              <p>
                We develop messaging that clearly articulates how to talk about your company
                so your buyers take action.
              </p>

              <p>
                Messaging includes a buyer-centric description of your products and services,
                your differentiation and the benefits you deliver.
              </p>
            </div>

            <div className="bucket w-100 w-25-l pl0 pl3-l">
              <h5 className="ttu">
                Operational Readiness
              </h5>

              <p>
                We recommend operational changes required for the strategy to work.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section id="buyer-voice" className="buyer-voice anchor">
        <h4 className="tc ttu">
          <strong>Buyer Voice</strong> Approach
        </h4>

        <p>
          At the core of the Quick Start is our Buyer Voice approach, which
          we use to quickly create the strategy, messaging and positioning,
          and other deliverables needed to ensure your company’s products
          and services resonate with your target buyers.
        </p>

        <p>
          Buyer Voice is based on market and competitive analysis, and takes
          advantage of Qualitative Primary Research (QPR). QPR delivers
          insight impossible to glean from any other source, and entails
          executive-to-executive interviews with key decision makers.
          Only QPR:
        </p>

        <ul>
          <li>
            Goes beyond typical Voice of Customer exercises to uncover factors
            central to buying decisions
          </li>

          <li>
            Delivers an executive perspective into market perception, core business
            triggers and pain points, key buying criteria, and perceived strengths and weaknesses
          </li>

          <li>
            Is the basis for our recommendations, which are typically actionable in 6 weeks
          </li>
        </ul>
      </section>
    </div>
  </ServiceTemplate>
);

export default GoToMarketStrategyPage;
